import React from "react";
import { useSelector } from "react-redux";
import { IoClose } from 'react-icons/io5';
import { Link, NavLink } from 'react-router-dom';
import Sheet from 'react-modal-sheet';
import { TbShoppingBag } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { FaArrowLeftLong } from "react-icons/fa6";
import { CheckBox } from "../filters";
const Mobilefilternew = () => {
    const dispatch = useDispatch()
    const filterShowSidebar = useSelector(state => state.masterdata.filterShowSidebar);
    const cartDetail = useSelector(state => state.cartdetail.cartDetail);
    const productcatData = useSelector(state => state.catlogdetail.productcatData);
    console.log("productcatData", productcatData)
    return (
        <div>
            {
                filterShowSidebar &&
                <Sheet className='' isOpen={filterShowSidebar} detent={'100vh'} onClose={() => dispatch({ type: 'setfilterShowSidebar', payload: false })}
                >
                    <Sheet.Container className="filtermodalsheet">
                        <Sheet.Content>
                            <div className='filtermobiletop'>
                                <div className='icon' onClick={() => dispatch({ type: 'setfilterShowSidebar', payload: false })
                                }><FaArrowLeftLong size="20" /></div>
                                <div className='filter_title'>Filters</div>
                                <Link to="/cart" className='text-decoration-none'>
                                    <div className='cart'>
                                        <TbShoppingBag className="" />
                                        <span className="custom-badge primaryColor">{cartDetail.result.filter(ele => ele.cart_status == 1).length}</span>
                                    </div>
                                </Link>
                            </div>
                            {
                                productcatData.filters.checkbox &&
                                productcatData.filters.checkbox.length > 0 &&
                                productcatData.filters.checkbox.map((ele, index) => {
                                    return (
                                        <CheckBox item={ele} type={index > 1 ? false : true} key={index} />
                                    )
                                })
                            }
                            <h1>hd</h1>
                            <h1>hd</h1>
                            <h1>hd</h1>
                            <h1>hd</h1>
                            <h1>hd</h1>
                            <h1>hd</h1>
                            <h1>hd</h1>
                            <h1>hd</h1>
                            <h1>hd</h1>
                            <h1>hd</h1>
                        </Sheet.Content>
                    </Sheet.Container>
                    <Sheet.Backdrop />
                </Sheet>
            }


        </div>
    )
}
export default Mobilefilternew