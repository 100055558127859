import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddressAction from '../../redux/actions/AddressAction';
import AddressForm from './AddressForm';
import { Modal } from 'react-bootstrap';
import { HiOutlinePlusSm } from "react-icons/hi";

const CheckoutAddressNew = () => {
    const dispatch = useDispatch();
    const { getAddressList } = AddressAction();
    const addressList = useSelector(state => state.addressdata.addressList);
    const openform = useSelector(state => state.addressdata.openform);
    const selectdelivertaddress = useSelector(state => state.orderdetail.selectdelivertaddress);
    const opencheckoutstatus = useSelector(state => state.orderdetail.opencheckoutstatus);
    const addressData = useSelector(state => state.addressdata.addressData);
    const openaddressModal = useSelector(state => state.addressdata.openaddressModal);
    const [selectedAddressId, setSelectedAddressId] = useState();
    const [openAddnewaddress, setopenAddnewaddress] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState(null);
    console.log("selectedAddress", selectedAddress)
    console.log("opencheckoutstatus", opencheckoutstatus)
    console.log("selectdelivertaddress", selectdelivertaddress)
    // Load addresses when component mounts
    useEffect(() => {
        dispatch(getAddressList());
    }, [dispatch]);

    const handleClose = () => {
        dispatch({ type: 'setopenform', payload: !openform })
    };
    const useaddress = () => {
        dispatch({ type: 'setopencheckoutstatus', payload: 2 })
        dispatch({ type: 'setchckoutpaymentonceopen', payload: true })
        // dispatch({ type: 'setselectdelivertaddress', payload: selectedAddress })
    }
    // Set the first address as the selected address when addressList is populated
    useEffect(() => {
        if (addressList && addressList.length > 0) {
            setSelectedAddressId(addressList[0].addressId);
            setSelectedAddress(addressList[0]); // Store the first address object
        }
    }, [addressList]);
    useEffect(() => {
        dispatch({ type: 'setselectdelivertaddress', payload: selectedAddress })
    }, [selectedAddress])
    const Addnewaddress = () => {
        setopenAddnewaddress(true)
    }
    // Handle address selection change
    const handleAddressChange = (addressId) => {
        const newSelectedAddress = addressList.find(address => address.addressId === addressId);
        setSelectedAddressId(addressId);
        setSelectedAddress(newSelectedAddress);
    };
    //add address
    const handlenewform = () => {
        dispatch({ type: 'setAddres', payload: '' })
        dispatch({ type: 'setaddressData', payload: [] })
        dispatch({ type: 'setopenform', payload: !openform })
        // dispatch({ type: 'setcheckoutnewaddress', payload: true })
        dispatch({
            type: 'setFormValues', payload: {
                name: "",
                phone: "",
                // mailid:"",
                address1: "",
                city: "",
                address2: "",
                pincode: "",
                country: "",
                state: "",
                addressId: "0",
                default: 1,
                addresstype: "Delivery"
            }
        })
    }
    //edit address
    const handleEdit = (addressId) => {
        // dispatch({ type: 'setErrors', payload: {} })
        dispatch({ type: 'setonchangeadress', payload: '' })
        dispatch({ type: 'setopenform', payload: true })
        dispatch({ type: 'setaddressData', payload: addressList.filter(ele => ele.addressId == addressId) })
        dispatch({ type: 'setAddres', payload: '' })

        // setchecked(addressId)
    }

    return (
        <div className="address-container">
            <div className='p-4'>
                <h6 className='checkout_address_title'>Your addresses</h6>
                {addressList && addressList.length > 0 ? (
                    addressList.map((address) => (
                        <div key={address.addressId} className={`${selectedAddressId === address.addressId ? "selectedcheckoutaddress" : ""} address-item`}>
                            <input
                                type="radio"
                                id={address.addressId}
                                name="address"
                                value={address.addressId}
                                checked={selectedAddressId === address.addressId}
                                onChange={() => handleAddressChange(address.addressId)}
                                className="address-radio"
                            />
                            <div className="address-label">
                                <strong className='pe-1 '>{address.name}</strong>
                                {address.address1}, {address.address2}, {address.city}, {address.state}, {address.pincode}, {address.country},
                                {/* <div className="address-actions"> */}
                                <a className="address-actions" onClick={() => handleEdit(address.addressId)}>Edit address</a>
                                {/* </div> */}
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No addresses available</p>
                )}
                <div className='accordion-deliver-viewmore mt-2 mb-2  contactAddressButton' onClick={() => handlenewform()}>
                    <HiOutlinePlusSm color="#ff7e00" style={{ marginTop: "-3px" }} /> Add new Address
                </div>

                {
                    openform &&
                    <Modal
                        show={openform}
                        size="sm"
                        className='my-custom-modal'
                        centered
                        onHide={() => handleClose()}
                    >
                        <Modal.Body>
                            <AddressForm showbtn={true} />
                        </Modal.Body>
                    </Modal>


                }
            </div>
            <div className='Useaddresmain'>
                <div className='checkoutbtns primaryColor ' onClick={useaddress}>
                    use this address
                </div>
            </div>
        </div>
    );
};

export default CheckoutAddressNew;
