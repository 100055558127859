import React, { useContext } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import Sidebar from '../sidebar/Sidebar';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CartSidebar from './CartSidebar';
const Addtocardsidebar = React.memo(() => {


  const showSidebar = useSelector(state => state.masterdata.showSidebar);
  const cartsidebardata = useSelector(state => state.masterdata.cartsidebardata);

  return (
    <>

      <div className="" style={
        {
          zIndex: showSidebar ? "10" : "0"
        }
      }>
        <div className='d-flex'>
          <div className='me-3' >
            <CartSidebar quantity={cartsidebardata.quantity} image={cartsidebardata.image} price={cartsidebardata.price} />
          </div>

        </div>





      </div>

    </>

  );
});

export default Addtocardsidebar;