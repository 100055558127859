import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import logo from '../../images/bg_image.webp';
import { NavLink } from 'react-router-dom';
const Banner1 = ({ singlebannerdata }) => {
    // const [first, second, third,fourth] = brands
    return (
        <div>
            {/* <Container fluid> */}
            <Row className='brand32-main '>

                {/* <Col lg={12} md={12} sm={12} xs={12} className='brands-col-maindiv'> */}
                <NavLink to={singlebannerdata.url}>
                    <div class="homepage_single_banner">
                        <img class="single_banner-img" src={singlebannerdata.card_image}></img>
                    </div>
                </NavLink>
            </Row>
            {/* </Container> */}
        </div>
    );
};

export default Banner1;