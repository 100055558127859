import React from "react";
import { Row, Col } from "react-bootstrap";
import CardProduct from "./Cardproduct";
import { NavLink } from 'react-router-dom';
const MostViewed = ({ data, heading, viewall, button_url }) => {
    return (
        <div>
            <div className="home-carousel-component-header">
                <div className="Quality-products">
                    {/* <h5 className="Quality-products-details-left1"></h5> */}
                    <h5 className="Quality-products-details-left2">{heading}</h5>
                </div>
                <NavLink className="text-decoration-none" to={button_url}>
                    <h5 className="home-carousel-componentheader-viewall">{viewall}</h5>
                </NavLink>
            </div>
            <Row>
                {
                    data.map((ele, index) => {
                        return (
                            <Col lg={2} md={3} sm={6} xs={6} key={index} className="col-md-5ths">
                                <CardProduct
                                    image={ele.product_images[0]}
                                    title={ele.product_title}
                                    rating={ele.rating}
                                    slug={ele.slug}
                                    status={ele.type}
                                    price={ele.selling_price}
                                    discount={ele.discount_percentage}
                                    productid={ele.productid}
                                    mrp={ele.mrp}
                                    stock_status={ele.stock_status}
                                    product_type={ele.product_type}
                                />
                            </Col>
                        );
                    })
                }
            </Row>
        </div>
    );
}

export default MostViewed;
