import React, { useState, useContext, useEffect } from 'react';
import { ForgotPassword1, ForgotPassword2, ForgotPassword3 } from '../components/common';
// import { StateContext } from '../components/context/ManageStates';
import { UseDispatch, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
const ForgotPassword = () => {
  const [formValuedata, setFormValuedata] = useState("");
  const  dispatch =useDispatch();
  const step = useSelector(state => state.masterdata.step);

//  useEffect =(()=>{
//     dispatch({ type: 'setStep', payload: 1 })
//   },[step])

useEffect(() => {
  dispatch({
    type: 'setHeadercategory',
    payload: false,
  });
}, []);
  return (
    <div>
      {
        step == 1 &&
        <ForgotPassword1 />
      }
      {
        step == 2 &&
        <ForgotPassword2  />
      }
      {
        step == 3 &&
        <ForgotPassword3 />
      }
    </div>
  );
};

export default ForgotPassword;