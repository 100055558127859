import React, { useContext, useEffect } from 'react';
import { Col, Container, NavLink, Row } from 'react-bootstrap';
import { InputBox } from '../components/common';
import { Link } from 'react-router-dom';
import validate from "../validations/FormValidationRules";
import useFormvalidation from '../validations/FormValidation'
import AuthAction from '../redux/actions/AuthAction';
import { useDispatch } from 'react-redux';
import { IoMdHeart } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import jwtDecode from "jwt-decode";
import { FaArrowLeftLong } from "react-icons/fa6";
import bubble1 from "../../src/images/bubble 01.png"
import bubble2 from "../../src/images/bubble 02.png"
import bubble3 from "../../src/images/bubblle 03.png"
import apple from "../../src/images/apple.png"
import facebook from "../../src/images/facebook.png"
import logo1 from "../../src/images/Logo (1).png";
import { GoogleLogin } from '@react-oauth/google';
const Login = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch({
            type: 'setHeadercategory',
            payload: false,
        });
    }, []);
    const navigate = useNavigate();
    const [formValues, setFormValues] = React.useState(
        // email: "",
        // password: "",

        localStorage.getItem("verification_method") == 1 ? {

            "phone": "",
            "password": "",
            ...(localStorage.getItem("sessionId") != null && { session_id: localStorage.getItem("sessionId") })
        }
            :
            {
                "email": "",
                "password": "",
                ...(localStorage.getItem("sessionId") != null && { session_id: localStorage.getItem("sessionId") })
            }
    );
    const { LoginApi } = AuthAction();
    const { values, errors, handleChange, setErrors, handleSubmit } = useFormvalidation(
        handleNext,
        validate,
        formValues
    );
    function handleNext() {
        dispatch(LoginApi(values, errors, setErrors))
    }
    const loginbackaction = () => {
        navigate(-1)
    }
    return (
        <Container>
            <Helmet>
                <title>{localStorage.getItem("title")} - login</title>
                <link rel="canonical" href={window.location.origin + window.location.pathname} />
                {/* <GoogleAnalytics trackingId={"G-ZDTRF1HJ43"}/> */}
            </Helmet>
            <div className='form_main_container '>
                <div className="formsdata_container login-width">
                    <div className='bubble1imag'><img src={bubble1}></img></div>
                    <div className='bubble2imag'><img src={bubble2}></img></div>
                    <div className='bubble3imag'><img src={bubble3}></img></div>
                    <div className='fomdataaa'>
                        <div lg={5} className="right-side loginregister">
                            <div onClick={loginbackaction}>
                                <div className='loginbackbtnicon'><FaArrowLeftLong />
                                    <span className='loginbackbtn' >Back</span>
                                </div>

                            </div>
                            <h4 className="login_text">Log in </h4>
                            <h6 className='loginscreen_goodtosee'>Good to see you back! <IoMdHeart className='loginheart' /></h6>
                            {
                                localStorage.getItem("verification_method") == 1 ?
                                    <Col lg={12}>
                                        <InputBox label={"Phone"} id={"phone"} handleChange={handleChange} errors={errors} />
                                    </Col>
                                    :
                                    <Col lg={12}>
                                        <InputBox label={"Email"} id={"email"} handleChange={handleChange} errors={errors} />
                                    </Col>
                            }
                            <InputBox label={"Password"} id={"password"} handleChange={handleChange} errors={errors} />

                            <div className='product-detail-buttongroup login-register-btn' onClick={handleSubmit}>
                                <div className={`product-detail-button form_submit_button_padding primaryColor `}>
                                    Login
                                </div>
                            </div>
                            <div className='loginscreen_forgotpassword'>
                                <Link to="/password-reset" className='signUpNavLink text-decoration-none  text-underline-none2'>
                                    Forgot your password?
                                </Link>
                            </div>
                            <div className='mt-5'>
                                <div className='linkwithggogle-apple'>
                                    Continue with Apple

                                    <div>
                                        <img className='linkwithggogle-appleicon' src={apple}></img>

                                    </div>
                                </div>
                                <div className='linkwithggogle-apple'>
                                    Continue with Facebook

                                    <div>
                                        <img className='linkwithggogle-appleicon' src={facebook}></img>

                                    </div>
                                </div>
                                <div className='linkwithggogle-apple'>
                                    Continue with Google

                                    <div>
                                        <img className='linkwithggogle-appleicon' src={logo1}></img>

                                    </div>
                                </div>
                            </div>
                            <div className='login-register'>New to Buyology?
                                <Link to="/register" className='signUpNavLink text-decoration-none  text-underline-none2'>
                                    <span className='loginscreen_forgotpassword marginleft'>Sign up</span>
                                </Link>
                            </div>
                            {/* <GoogleLogin
                                onSuccess={(credentialResponse) => {
                                    const decoded = jwtDecode(credentialResponse?.credential);
                                    console.log("wwww", decoded);
                                }}
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                            />; */}

                        </div>
                    </div>



                </div>
            </div>

        </Container >



    );
};

export default Login;