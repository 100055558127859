import React from 'react';
import { FaTag,FaCircleArrowRight } from "react-icons/fa6";

import { Row, Col } from 'react-bootstrap';
import bankoffers from "../../images/bankoffers.png"
const DetaiBankOffers = () => {
    const data = [
        {
            title: "Buy Now, Pay Later",
            sectionresult: [
                {
                    content: "East Installment Pay with 0% intrest",
                    price: 12345,
                }
            ]

        },
        {
            title: "Bank Offers",
            sectionresult: [
                {
                    content: "East Installment Pay with 0% intrest",
                    price: 12345,
                },
                {
                    content: "East Installment Pay with 0% intrest",
                    price: 12345,
                },
                {
                    content: "East Installment Pay with 0% intrest",
                    price: 12345,
                }
            ]
        }

    ]
    return (
        <Row className='productdetail-bankoffers-main'>
            <Col >
                <div >
                    {
                        data.map(ele => {
                            return (

                                <div className='product_detailBankoffers'>
                                    <div className='d-flex justify-content-between'>

                                    <div className='bankoffers_title'>{ele.title}</div>
                                    <div className='bankoffers_seelall'>
                                        See All<FaCircleArrowRight className='Bankoffers_arrowicon' size={30} />
                                    </div>
                                    </div>
                                    {
                                        ele.sectionresult.map(ele1 => {
                                            return (
                                                <Col lg={8} className='bankoffers_content'>
                                                    <div>
                                                        <FaTag className='bankoffers_tagicon' />
                                                    </div>
                                                    <div className='bankoffers_offers'>
                                                        {ele1.content} <span className='bankoffers-Price'>AEU{ele1.price}</span>
                                                        <span><img className="bankoffer_image" src={bankoffers} /></span>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }

                                </div>


                            )
                        })
                    }
                </div>
            </Col>


        </Row>

    )
}
export default DetaiBankOffers;