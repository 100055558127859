import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { NavLink } from 'react-router-dom';
import logo from '../../images/bg_image.webp';
const HomeCard = ({ data }) => {
    const isMobile = useMediaQuery({ query: `(max-width: 576px)` });
    return (
        <div className="card-product">
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 className='card-product-title'>{data.heading}</h5>
                {
                    data.hasOwnProperty("button_label") &&
                    isMobile &&
                    <h5 className="card-product-btn">{data.button_label}</h5>
                }
            </div> */}

            <Row className="card-product-nested-card">
                {
                    data.hasOwnProperty("items") ? data.items.slice(0, 4).map((item, index) => {
                        return (

                            <Col lg={6} md={6} sm={6} xs={6} key={index} className='card-main'>
                                <NavLink to={`/product/${item.slug}`} className="text-decoration-none">
                                    <div className="card-nested">
                                        <img
                                            referrerPolicy="no-referrer"
                                            src={item.image}
                                            onError={e => {
                                                e.currentTarget.src = logo
                                            }}

                                        />
                                        {/* <div className='card-title'>{item.title}</div> */}
                                        {/* <h5 className='card-nested-title'>{item.title}</h5> */}
                                    </div>

                                </NavLink>
                            </Col>
                        )
                    }) :
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <img
                                referrerPolicy="no-referrer"
                                onError={e => {
                                    e.currentTarget.src = logo
                                }}
                                src={data.banner_image} width="100%" />
                        </Col>
                }
            </Row>
            {/* {

                data.hasOwnProperty("button_label") &&
                !isMobile &&

                <NavLink to={data.url} className="text-decoration-none">
                    <div className="card-product-btn">{data.button_label}</div>
                </NavLink>
            } */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h5 className='card-product-title'>{data.heading}</h5>
                {/* {
                    data.hasOwnProperty("button_label") &&
                    // isMobile &&
                    <h5 className="card-product-btn">{data.button_label}</h5>
                } */}
            </div>
        </div>
    );
};
export default HomeCard;