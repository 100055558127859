import React, { useContext, useEffect, useState } from 'react';
import { BsTruck } from "react-icons/bs";
import { FaStore } from "react-icons/fa6";
import { Container, Row, Col } from 'react-bootstrap';
import { useMediaQuery } from "react-responsive";
import { CheckoutTabs, CHeckoutAddress, CartPaymentMethod, Completepayment, PaymentRazarpay, CheckotDeliverySlot, CheckoutAddressNew, CartList, PickupAddressList, CHeckoutPaymentMethod, MobileCheckout, AccountInfo } from '../components/common';
import { useSelector, useDispatch } from 'react-redux';
import MasterAction from '../redux/actions/MasterAction';
import { Helmet } from 'react-helmet';
import Accordion from 'react-bootstrap/Accordion';
import { useSearchParams } from 'react-router-dom';
import { Form } from "react-bootstrap";
import OrderAction from '../redux/actions/OrderAction';
const Checkout = () => {
    const dispatch = useDispatch()
    const [searchParams, setSearchParams] = useSearchParams();
    const { getProfileData } = MasterAction()
    const { conformpaymentservicedata } = OrderAction()
    const isDesktoporMobile = useMediaQuery({ query: `(max-width: 991px)` });
    const [checked, setchecked] = useState(1)
    const masterData = useSelector(state => state.masterdata.masterData);
    const selectedColor = useSelector(state => state.orderdetail.selectedColor);
    const addressList = useSelector(state => state.addressdata.addressList);
    const profileData = useSelector(state => state.masterdata.profileData);
    const opencheckoutstatus = useSelector(state => state.orderdetail.opencheckoutstatus);
    const selectdelivertaddress = useSelector(state => state.orderdetail.selectdelivertaddress);
    const selectedAddress = useSelector(state => state.orderdetail.selectedAddress);
    const chckoutpaymentonceopen = useSelector(state => state.addressdata.chckoutpaymentonceopen);
    const userData = useSelector(state => state.masterdata.userData);

    const handleClick = (id) => {
        setchecked(id)
    }
    const openaddress = () => {
        dispatch({ type: 'setopencheckoutstatus', payload: 1 })

    }
    const openpayment = () => {
        dispatch({ type: 'setopencheckoutstatus', payload: 2 })
    }
    const usepayment = () => {
        dispatch({ type: 'setopencheckoutstatus', payload: 3 })
    }
    useEffect(() => {
        if (addressList && addressList.length > 0) {
            dispatch({ type: 'setselectdelivertaddress', payload: addressList[0] })
        }
    }, [addressList]);
    console.log("profileData", profileData)
    console.log("opencheckoutstatus", opencheckoutstatus)
    console.log("selectdelivertaddress", selectdelivertaddress)
    useEffect(() => {
        if (searchParams.has("payment")) {
            const url = new URL(window.location.href);
            const token = url.searchParams.get('token');
            const input_data = {
                token: searchParams.get('token')
            }
            dispatch(conformpaymentservicedata(input_data))
        }
    }, [])

    useEffect(() => {
        dispatch(getProfileData())
    }, [])
    return (
        <>
            <Helmet>
                <title>{localStorage.getItem("title")} - Checkout</title>
                <link rel="canonical" href={window.location.origin + window.location.pathname} />
                {/* <GoogleAnalytics trackingId={"G-ZDTRF1HJ43"}/> */}
            </Helmet>
            <Container >
                <Row >
                    <Col lg={8} className="mt-4 mb-4">
                        {profileData.length > 0 && (profileData[0].mailid === 'null' || profileData[0].mailid === '') && (
                            <CheckoutTabs id={5} title={"Add / Edit email"} info={<AccountInfo />} />
                        )}
                        {
                            opencheckoutstatus == 1 ?
                                <div className='checkout_title_headings'>
                                    <div className='checkout_titles_first'>

                                        <span className='pe-3'>1</span>   Select a delivery address

                                    </div>
                                    {
                                        <CheckoutAddressNew />
                                    }

                                </div> :
                                <div className='checkout_title_headings'>
                                    <div className='selecteddeliveryaddress'>
                                        <div className='checkout_deliveryaddress_title'>
                                            <span className='pe-3'>1</span>  Delivery address
                                            <div className='holeaddress'>
                                                <div className='checout_deliveryaddress'>{selectdelivertaddress.name}</div>
                                                <div className='checout_deliveryaddress'>{selectdelivertaddress.address1}</div>
                                                <div className='checout_deliveryaddress'>{selectdelivertaddress.address2}</div>
                                                <div className='checout_deliveryaddress'>{selectdelivertaddress.city},{selectdelivertaddress.state}</div>
                                            </div>
                                        </div>

                                        <a className='changebtn_selecteddelivery' onClick={openaddress}>
                                            Change
                                        </a>
                                    </div>
                                </div>
                        }
                        {
                            opencheckoutstatus == 2 ?
                                <div className='checkout_title_headings'>
                                    <div className='checkout_titles_first'>
                                        <span className='pe-3'>2</span>  Select a payment method
                                    </div>
                                    <div className="address-container">
                                        {/* <Completepayment/> */}
                                        <PaymentRazarpay />
                                        {/* <Form.Check
                                            type="radio"
                                            name="paymenttype"
                                            className='m-3'
                                            value={2}
                                            label="Cod"
                                            checked={checked}
                                            aria-label="radio 1"
                                        /> */}
                                        <div className='Useaddresmain'>
                                            <div className='checkoutbtns primaryColor ' onClick={usepayment}>
                                                use this payment
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <div className='checkout_title_headings'>
                                    <div className='selecteddeliveryaddress'>
                                        <div className='checkout_deliveryaddress_title'>
                                            <span className='pe-3'>2</span>  Payment method
                                            <div className='holeaddress'>
                                                <div className='checout_selected_payment'>{selectedColor.split("@")[1]}</div>

                                            </div>
                                        </div>
                                        {
                                            chckoutpaymentonceopen &&
                                            <a className='changebtn_selecteddelivery' onClick={openpayment}>
                                                Change
                                            </a>
                                        }


                                    </div>

                                </div>



                        }

                        {
                            opencheckoutstatus == 3 ?
                                <div className='checkout_title_headings'>
                                    <div className='checkout_titles_first'>
                                        <span className='pe-3'>3</span>  Items and delivery
                                    </div>
                                    {
                                        // screentype == "checkout" &&
                                        <>
                                            <CartList type={"checkout"} />
                                            {/* <div className=''>
                                                        <div className='checkoutbtns primaryColor '>
                                                            Complete Payment
                                                        </div>
                                                    </div> */}
                                            {/* <Row className="address-container">
                                                <Col lg={4} >
                                                    <CartPaymentMethod screentype={"checkout"} onlybtn={true} />
                                                </Col>
                                                <Col lg={8} >
                                                </Col>
                                            </Row> */}

                                        </>

                                    }
                                </div> : <div className='checkout_deliveryaddress_title checkout_deliveryaddress_title-bottom2'>
                                    <span className='pe-3'>3</span>  Items and delivery
                                </div>

                        }




                    </Col>
                    <Col lg={4} className="checkout-cart-paymentmethod">
                        <CartPaymentMethod screentype={"checkout"} />
                    </Col>
                </Row>
            </Container>
        </>

    );
};

export default Checkout;