import React, { useState, useEffect } from "react";

const CatlogPriceFilter = () => {
  const initialMinPrice = 0;
  const initialMaxPrice = 10000;

  const [sliderMinValue] = useState(initialMinPrice);
  const [sliderMaxValue] = useState(initialMaxPrice);

  const [minVal, setMinVal] = useState(initialMinPrice);
  const [maxVal, setMaxVal] = useState(initialMaxPrice);

  const [isDragging, setIsDragging] = useState(false);

  const minGap = 5;

  // Adjust the slider track dynamically when min or max values change
  useEffect(() => {
    setSliderTrack();
  }, [minVal, maxVal]);

  const slideMin = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= sliderMinValue && maxVal - value >= minGap) {
      setMinVal(value);
    }
  };

  const slideMax = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value <= sliderMaxValue && value - minVal >= minGap) {
      setMaxVal(value);
    }
  };

  // Update the slider track dynamically
  const setSliderTrack = () => {
    const range = document.querySelector(".slider-track");
    const minTooltip = document.querySelector(".min-tooltip");
    const maxTooltip = document.querySelector(".max-tooltip");

    if (range && minTooltip && maxTooltip) {
      const minPercent = ((minVal - sliderMinValue) / (sliderMaxValue - sliderMinValue)) * 100;
      const maxPercent = ((maxVal - sliderMinValue) / (sliderMaxValue - sliderMinValue)) * 100;

      range.style.left = `${minPercent}%`;
      range.style.right = `${100 - maxPercent}%`;

      minTooltip.style.left = `calc(${minPercent }% - 1px)`; // Adjust tooltip position
      maxTooltip.style.left = `calc(${maxPercent -10 }% - 0px)`; // Adjust tooltip position
    }
  };

  const startDrag = () => {
    setIsDragging(true);
  };

  const stopDrag = () => {
    setIsDragging(false);
  };

  return (
    <>
      <div className="filterprice_title">Price</div>
      <div className="double-slider-box">

        <div className="range-slider">
          <div className="slider-track"></div>
          <input
            type="range"
            min={sliderMinValue}
            max={sliderMaxValue}
            value={minVal}
            onChange={slideMin}
            onMouseDown={startDrag}
            onMouseUp={stopDrag}
            onTouchStart={startDrag}
            onTouchEnd={stopDrag}
            className="min-val"
          />
          <input
            type="range"
            min={sliderMinValue}
            max={sliderMaxValue}
            value={maxVal}
            onChange={slideMax}
            onMouseDown={startDrag}
            onMouseUp={stopDrag}
            onTouchStart={startDrag}
            onTouchEnd={stopDrag}
            className="max-val"
          />
          <div className={`min-tooltip ${isDragging ? "visible" : ""}`}>
            {minVal}
          </div>
          <div className={`max-tooltip ${isDragging ? "visible" : ""}`}>
            {maxVal}
          </div>
        </div>
      </div>
    </>

  );
};

export default CatlogPriceFilter;
