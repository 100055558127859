import React from 'react';
import { MdOutlineStarPurple500, MdOutlineStarOutline } from "react-icons/md";
import ratingimg from '../../images/ratingimg.png';
import {Row,Col} from 'react-bootstrap';
const RatingNew = () => {
    return (
        <div className='ratingNewSection'>
            <div className='rating_and_review_title'>Rating & Reviews</div>
            <div className=' Ratingnew_star_icons'>

                <MdOutlineStarPurple500 size={30} style={{ color: " #ECA61B" }} />
                <MdOutlineStarPurple500 size={30} style={{ color: " #ECA61B" }} />
                <MdOutlineStarPurple500 size={30} style={{ color: " #ECA61B" }} />
                <MdOutlineStarPurple500 size={30} style={{ color: " #ECA61B" }} />
                <MdOutlineStarOutline size={30} style={{ color: " #ECA61B" }} />
                <div className='averagerating'>4/5</div>
            </div>
            <div className='ratingnew_data'>
                <div className='ratingprofileimage'>
                    <img className='rating_image' src={ratingimg}></img>
                </div>
                <div>
                    <div className='cusromername'>Muzaina</div>
                    <div className='individual_Ratingnew_star_icons'>
                        <MdOutlineStarPurple500 size={20} style={{ color: " #ECA61B" }} />
                        <MdOutlineStarPurple500 size={20} style={{ color: " #ECA61B" }} />
                        <MdOutlineStarPurple500 size={20} style={{ color: " #ECA61B" }} />
                        <MdOutlineStarPurple500 size={20} style={{ color: " #ECA61B" }} />
                        <MdOutlineStarOutline size={20} style={{ color: " #ECA61B" }} />

                    </div>
                    <div className='review_data'>
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed ...
                    </div>
                </div>

            </div>
            
            <Col lg={6} className={`Rating_viewall_button primaryColor`}
            >
                View All Reviews
            </Col>
        </div>
    )
}
export default RatingNew;