import React, { useContext, useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import avatar from '../../images/avatar.webp';
import { IoMdNotifications, IoIosArrowUp } from 'react-icons/io'
import { FaMapMarkerAlt, FaPowerOff } from 'react-icons/fa';
import { BsPersonCircle, BsFillBoxFill, BsFillCaretUpFill, BsHeartFill } from 'react-icons/bs'
import { FaRegHeart } from "react-icons/fa";
import logo from '../../images/logo.png'
import { IoPersonSharp } from "react-icons/io5";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { PiSquaresFourBold } from "react-icons/pi";
import { TbShoppingBag } from "react-icons/tb";
import SearchBar from './SearchBar';
import { AiOutlinePercentage } from "react-icons/ai";
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Modal } from 'react-bootstrap';
import HeaderDropdown from '../common/HeaderDropdown';
import flag from "../../../src/images/flag.png"
import { ModalAddressList } from '../common';
import { FiShoppingCart } from "react-icons/fi";
import { GoPerson } from "react-icons/go";
// import logo from '../../images/bg_image.webp';
import { useSelector, useDispatch } from 'react-redux';
import { useCookie } from '../../hooks';
import { useMediaQuery } from 'react-responsive';

const Header = React.memo(() => {
  const { deleteCookie } = useCookie();
  const [selectedOption, setSelectedOption] = useState(null);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false); // Added state for dropdown
  const langdata = useSelector(state => state.masterdata.langdata);
  const isDesktopOrMobile = useMediaQuery({ query: '(max-width: 1150px)' })
  const slectedkeys = useSelector(state => state.masterdata.slectedkeys);
  const keysdata = langdata && langdata[0]
    ? Object.keys(langdata[0])
    : [];
  const dispatch = useDispatch();
  const cartDetail = useSelector(state => state.cartdetail.cartDetail);
  const authStatus = useSelector(state => state.masterdata.authStatus);
  const Headercategory = useSelector(state => state.masterdata.Headercategory);
  const addressList = useSelector(state => state.addressdata.addressList);
  const openaddressModal = useSelector(state => state.addressdata.openaddressModal);
  const userData = useSelector(state => state.masterdata.userData);
  const [addDetail, setAddDetail] = useState([]);

  const clicked = (data) => {
    dispatch({
      type: 'setslectedkeys',
      payload: data
    });
    setSelectedOption(data);
  };

  useEffect(() => {
    if (addressList !== undefined) {
      const add = addressList.filter(ele => ele.default === 1);
      setAddDetail(add);
    }
  }, [addressList]);

  const handleClose = () => {
    dispatch({ type: 'setopenaddressModal', payload: !openaddressModal });
  };

  const toggleCategoryDropdown = () => {
    dispatch({
      type: 'setHeadercategory',
      payload: !Headercategory
    });
    // setShowCategoryDropdown(prevState => !prevState);
  };
  const top = () => {
    window.scrollTo(0, 0);
  }

  return (
    <div className='Main_header'>
      <div className="header custom_header">
        <div className='header-item-left'>
          <div className="logo" onClick={top}>
            <Link to="/">
              <img referrerPolicy="no-referrer" src={localStorage.getItem('company_logo') !== null ? localStorage.getItem('company_logo') : logo}
                onError={e => {
                  e.currentTarget.src = logo;
                }}
              />
              {/* <img referrerPolicy="no-referrer" src={logo}
                onError={e => {
                  e.currentTarget.src = logo;
                }}
              /> */}
            </Link>
          </div>
        </div>
        <div className='searchbar'>
          <SearchBar />
        </div>
        <div className='Header-discountsection'>
          <div className='discount-div'>
            <AiOutlinePercentage className="discount-icon" />
          </div>
          <div className='discount-rightside'>
            <div className='discount-righttop'>
              Only this month
            </div>
            <div className='discount-rightbottom'>
              Super Sale 20%
            </div>
          </div>
        </div>

        <div className='header-item-right'>
          <div>
            <FaRegHeart className='Header_whishlist' />
          </div>
          <div className='ms-4 my_Account_header' style={{ display: "flex" }}>
            <div>
              <NavDropdown
                id="nav-dropdown-dark-example"
                title={<IoPersonSharp className="me-2 icon_color Header_profileicon" />}
              >
                {authStatus ? (
                  <>
                    <NavDropdown.Item>
                      <NavLink to={"/my-account"} className="text-decoration-none">
                        <div className='Profile-Dropdown'>
                          <div className='Profile-Dropdown-icon primaryColorcolor'><BsPersonCircle /></div>
                          <div className='Profile-Dropdown-name'>My Account</div>
                        </div>
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink to={"/my-orders"} className="text-decoration-none">
                        <div className='Profile-Dropdown'>
                          <div className='Profile-Dropdown-icon primaryColorcolor'><BsFillBoxFill /></div>
                          <div className='Profile-Dropdown-name'>Orders</div>
                        </div>
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <NavLink to={"/wishlist"} className="text-decoration-none">
                        <div className='Profile-Dropdown'>
                          <div className='Profile-Dropdown-icon primaryColorcolor'><BsHeartFill /></div>
                          <div className='Profile-Dropdown-name'>Wishlist</div>
                        </div>
                      </NavLink>
                    </NavDropdown.Item>
                    <NavDropdown.Item>
                      <div className='Profile-Dropdown'>
                        <div className='Profile-Dropdown-icon primaryColorcolor'><IoMdNotifications /></div>
                        <div className='Profile-Dropdown-name'>Notifications</div>
                      </div>
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={deleteCookie}>
                      <div className='Profile-Dropdown'>
                        <div className='Profile-Dropdown-icon primaryColorcolor'><FaPowerOff /></div>
                        <div className='Profile-Dropdown-name'>Sign Out</div>
                      </div>
                    </NavDropdown.Item>
                    <div className='Profile-Dropdown-icon-arrow primaryColorcolor'><BsFillCaretUpFill /></div>
                  </>
                ) : (
                  <NavDropdown.Item>
                    <NavLink to={"/login"} className="text-decoration-none">
                      <div className='Profile-Dropdown'>
                        <div className='Profile-Dropdown-icon primaryColorcolor'><FaPowerOff /></div>
                        <div className='Profile-Dropdown-name'>Sign In</div>
                      </div>
                    </NavLink>
                  </NavDropdown.Item>
                )}
              </NavDropdown>
            </div>
          </div>

          <Link to="/cart" className='text-decoration-none'>
            <div className='cart'>
              <TbShoppingBag className="cartIcon Header_cart" />
              <span className="custom-badge primaryColor">{cartDetail.result.filter(ele => ele.cart_status == 1).length}</span>
            </div>
          </Link>
        </div>

        <Modal
          show={openaddressModal}
          size="sm"
          className='my-custom-modal'
          centered
          onHide={() => handleClose()}
        >
          <Modal.Body>
            <ModalAddressList />
          </Modal.Body>
        </Modal>
      </div>


      <div className='header-bottom'>
        <div className='d-flex'>
          <div className='category_section'>
            <div className='categoriesdiv' onClick={toggleCategoryDropdown}>
              <div className='categoryitem'>
                <PiSquaresFourBold size="18" className='categoryiconhedaer' />
                <div className='header-category-title'>Categories</div>
              </div>
              <div>
                {
                  Headercategory ?
                    <MdOutlineKeyboardArrowDown size="16" /> :
                    <IoIosArrowUp size="16" />
                }

              </div>
            </div>

            {/* Conditionally render the dropdown */}
            {Headercategory && (
              <div className='category-dropdown' style={{ background: '#f0f0f0', position: 'absolute', zIndex: 1000 }}>
                <HeaderDropdown />
              </div>
            )}
          </div>
          {
            !isDesktopOrMobile &&
            <div className='d-flex'>
              <div className='header-navlinks'>
                <div className='header-navlinks-title'>
                  Best Sellers
                </div>

              </div>
              <div className='header-navlinks'>
                <div className='header-navlinks-title'>
                  Today’s Deals
                </div>

              </div>
              <div className='header-navlinks'>
                <div className='header-navlinks-title'>
                  New Arrivals
                </div>

              </div>
              <div className='header-navlinks'>
                <div className='header-navlinks-title'>
                  Gift Cards
                </div>

              </div>
              <div className='header-navlinks'>
                <div className='header-navlinks-title'>
                  Help Center
                </div>

              </div>
            </div>
          }

        </div>
        <div className='header-languages'>
          Eng
          <div className='languages_arrow'><MdOutlineKeyboardArrowDown size="14" /></div>
        </div>
        <div className='header-languages'>
          <img className='headerflag' src={flag}></img>

          <div className='languages_arrow'>
            UAE
          </div>

          <div className='languages_arrow'><MdOutlineKeyboardArrowDown size="14" /></div>
        </div>
        <div>

        </div>

      </div>
    </div>
  );
});

export default Header;
