import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBhgZtBiBcVfuGCOtDdGmg5GcSe0cauYSY",
  authDomain: "apnashope-6fe99.firebaseapp.com",
  projectId: "apnashope-6fe99",
  storageBucket: "apnashope-6fe99.appspot.com",
  messagingSenderId: "799589421316",
  appId: "1:799589421316:web:13993114620710137f8051",
  measurementId: "G-66J7RXY6QR"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);