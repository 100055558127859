import React, { useState } from "react";
import { MdChevronRight } from "react-icons/md";
import { useSelector, useDispatch } from 'react-redux';
import advertagementimage from '../../images/advertisement.jpeg'
import { NavLink } from "react-router-dom";

const HeaderDropdown = () => {
    const categoryData = useSelector(state => state.masterdata.categoryData);
    const subcategoryfilter = useSelector(state => state.masterdata.subcategoryfilter);
    const Headercategory = useSelector(state => state.masterdata.Headercategory);
    const [opensub, setopensub] = useState(null); // Track which category's subcategories are open
    const dispatch = useDispatch();
    console.log("opensub", opensub)

    const opensubcat = (id) => {
        // Filter and dispatch the subcategory data based on the clicked category
        const data = categoryData.filter(ele => ele.id === id);
        dispatch({
            type: 'setsubcategoryfilter',
            payload: data
        });
        // Set the ID of the open category, or toggle it off if it's already open
        setopensub(opensub === id ? null : id);
    };
    const closecat = () => {
        dispatch({
            type: 'setHeadercategory',
            payload: !Headercategory
        });
    }

    return (
        <div className="dropdowncat">
            <div className='homecategorypage'>
                {categoryData && categoryData.length > 0 && categoryData.map(ele => (
                    <div className={`homepage-category `} key={ele.id} style={{ background: opensub == ele.id ? " #F5F7FA" : "" }}>
                        <div>
                            <img
                                className='category-image'
                                src={localStorage.getItem("cdn") + ele.image}
                                alt={ele.name}
                            />
                        </div>
                        <div className='categorytitle'>
                            <NavLink to={`/category/${ele.slug}`} className="text-decoration-none" onClick={closecat}>
                                {ele.name}
                            </NavLink>
                        </div>
                        <MdChevronRight
                            className="hearercategory_righticon"
                            size="16"
                            onClick={() => opensubcat(ele.id)}
                        />
                    </div>
                ))}
            </div>

            {/* Subcategory section */}
            {opensub && (
                <div className="subcatdata-header">
                    <div className="subcategory_subdiv">
                        {subcategoryfilter.length > 0 && subcategoryfilter[0].childCategory &&
                            subcategoryfilter[0].childCategory.map(subcat => (
                                <div key={subcat.id} className="dropdown_childcategory">
                                    <NavLink to={`/subcategory/${subcat.slug}`} className="text-decoration-none" onClick={closecat}>
                                        {subcat.name}
                                    </NavLink>


                                    {
                                        subcat.childCategory.map(ele => {

                                            return (
                                                <div className="dropdown-subcategory">
                                                    <NavLink to={`/subcategory/${ele.slug}`} className="text-decoration-none" onClick={closecat}>
                                                        {ele.name}
                                                    </NavLink>
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                            ))}
                    </div>
                    <div>
                        <img src={advertagementimage} className="advertisemantimage">
                        </img>
                    </div>
                </div>
            )}
        </div>
    );
}

export default HeaderDropdown;
