import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FiShoppingCart } from "react-icons/fi";
import { BsSearch, BsChevronLeft } from 'react-icons/bs';
import Sidebar from '../sidebar/Sidebar';
import { FaRegHeart } from "react-icons/fa";
import { TbShoppingBag } from "react-icons/tb";
import SearchBar from './SearchBar';
import { useLocation, useNavigate } from 'react-router-dom';
import ProductJsonData from '../../components/data//ProductJsonData';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../../images/bg_image.webp';
const MobileHeader = () => {
    const dispatch = useDispatch()
    const cartDetail = useSelector(state => state.cartdetail.cartDetail);
    const { data } = ProductJsonData();
    var location = useLocation();

    const navigate = useNavigate();
    const goBack = () => {
        window.scrollTo(0, 0);
        navigate(-1);
    }
    var pathname = location.pathname.split("/")[1];

    var updated_Data = data.filter(ele => ele.url == "/" + pathname);

    return (
        <>
            {
                pathname != 'order-success' &&
                <div className="mobile-header" style={{ position: "sticky" }}>
                    <div className={`header-item-top ${updated_Data.length > 0 ? "header-item-top2" : ""}`}>
                        <div style={{ display: "flex" }}>
                            {pathname == '' ? <Sidebar /> : <BsChevronLeft onClick={goBack} size="24" style={{ marginTop: "13px" }} />}
                            <>
                                {
                                    updated_Data.length > 0 ?
                                        <div className="header_title">
                                            {
                                                updated_Data[0].name
                                            }
                                        </div>
                                        :
                                        <div className="logo">
                                            <Link to="/"><img referrerPolicy="no-referrer" alt="Logo" src={localStorage.getItem('company_logo')}
                                                onError={e => {
                                                    e.currentTarget.src = logo
                                                }}
                                            /></Link>
                                        </div>
                                }
                            </>
                        </div>


                        {
                            updated_Data.length <= 0 &&

                            <div className='mobileHeader-icons'>
                                <div>
                                    <FaRegHeart className='Header_whishlist' />
                                </div>


                                <Link to="/cart" className="text-decoration-none">
                                    <div className='cart'>
                                        <div>
                                            <TbShoppingBag
                                                className="cartIcon Header_cart"

                                            />{" "}
                                            <span className="custom-badge">{cartDetail.result.filter(ele => ele.cart_status == 1).length}</span>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        }


                    </div>
                    <div className='mobile_searchbar'>
                        <SearchBar />
                    </div>
                </div>
            }
        </>


    );
};

export default MobileHeader;