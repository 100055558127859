import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import { MdKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { TbGridDots } from 'react-icons/tb';
import { MdOutlineFormatListBulleted } from "react-icons/md";

import { useMediaQuery } from 'react-responsive';
import ReactPaginate from 'react-paginate';
import { NodataFound, ProductCard } from '../common';
import Cardproduct from './Cardproduct';
import Productcard1 from './Productcard1';

const CatProducts = React.memo(({ type }) => {
    const dispatch = useDispatch();
    const isDesktopOrLaptop = useMediaQuery({ query: '(max-width: 991px)' });
    const productcatData = useSelector(state => state.catlogdetail.productcatData);
    const filtertotaldata = useSelector(state => state.catlogdetail.filtertotaldata);
    const categorylimit = useSelector(state => state.masterdata.categorylimit);
    const productcatFiltersData = useSelector(state => state.catlogdetail.productcatFiltersData);
    const checkItems = useSelector(state => state.catlogdetail.checkItems);
    const hasMore = useSelector(state => state.catlogdetail.hasMore);
    const filterPage = useSelector(state => state.catlogdetail.filterPage);
    const [searchParams, setSearchParams] = useSearchParams();
    const [activeSortBy, setActiveSortBy] = useState('New Arrivals');
    const [catPage, setCatPage] = useState(true);
    const [currentPage, setCurrentPage] = useState(filterPage); // Initialize with filterPage
    const prev = 'Prev';
    const next = 'Next';
    const categorydata = localStorage.getItem("categorylimit");
    const handleRemove = (title, value) => {
        const paramsValue = searchParams.get(title);
        const paramsArray = paramsValue.split(',');
        const filteredArray = paramsArray.filter(ele => ele !== value);
        if (filteredArray.length > 0) {
            searchParams.set(title, filteredArray.toString());
        } else {
            searchParams.delete(title);
        }
        setSearchParams(searchParams);
    };

    const handleClick = (val) => {
        if (val === 'new_arrivals') {
            searchParams.delete('order_by');
            setActiveSortBy('New Arrivals');
        } else {
            searchParams.set('order_by', val);
            setActiveSortBy(val === 'asc' ? 'Low to High' : 'High to Low');
        }
        setSearchParams(searchParams);
    };

    useEffect(() => {
        const queryObj = Object.fromEntries([...searchParams]);
        const sortParam = queryObj.order_by;
        if (sortParam) {
            setActiveSortBy(
                sortParam === 'asc'
                    ? 'Low to High'
                    : sortParam === 'desc'
                        ? 'High to Low'
                        : 'New Arrivals'
            );
        }
    }, [searchParams]);

    useEffect(() => {
        setCurrentPage(filterPage); // Sync currentPage with filterPage
    }, [filterPage]);

    const handlePageClick = (clickEvent) => {
        const selectedPage = clickEvent.selected + 1; // Adding 1 because pagination is usually 1-based
        if (filterPage !== selectedPage) {
            dispatch({ type: 'setFilterPage', payload: selectedPage });
            setCurrentPage(selectedPage); // Update currentPage immediately
            dispatch({ type: 'setloader', payload: true });
            window.scrollTo(0, 0);
        }
    };

    return (
        <Col xxl={10} lg={9} sm={12} xs={12}>
            <Row className='mb-2'>
                <Col lg={9} className="filter-summary-filterList">
                    {productcatData.filters.checkbox
                        .map(element => ({
                            ...element,
                            list: element.list.filter(subElement => checkItems.includes(subElement.attr_value_id))
                        }))
                        .filter(ele => ele.list.length > 0)
                        .map(ele1 => ele1.list.map(ele2 => (
                            <div className='filter-summary-filter' key={ele2.attr_value_id}>
                                <div className='filter-summary-parent'>
                                    <h5 className='filter-details-data'>{ele2.attr_value_name}</h5>
                                    <div className='filter-summary-removeIcon' onClick={() => handleRemove(ele1.title, ele2.attr_value_id)}>
                                        <IoClose size="15" />
                                    </div>
                                </div>
                            </div>
                        )))
                    }
                </Col>
            </Row>
            <Row className='react-pagination-maindiv'>
                <Col lg={9} className='category-top-lefticons'>
                    {/* <TbGridDots className={`carticons-top ${catPage ? "primaryColorcolor" : ""}`} onClick={() => setCatPage(true)} /> */}
                    {/* <MdOutlineFormatListBulleted className={`carticons-top ${!catPage ? "primaryColorcolor" : ""}`} onClick={() => setCatPage(false)} /> */}
                    <div className='category-top-content'>There Are <span className='category-count-div'>{filtertotaldata.filters}</span> Products</div>
                </Col>
                {!isDesktopOrLaptop && (
                    <Col lg={3}>
                        <div className="hList">
                            <div>
                                <div className="menu">
                                    <div className="menu-title menu-title_3rd">Sort By: <span className='active-title primaryColorcolor'>{activeSortBy}</span></div>
                                    <div className="menu-dropdown">
                                        <div onClick={() => handleClick('new_arrivals')} className={`${activeSortBy === 'New Arrivals' ? 'primaryColorcolor' : ''}`}>New Arrivals</div>
                                        <div onClick={() => handleClick('asc')} className={`${activeSortBy === 'Low to High' ? 'primaryColor' : ''}`}>Price: Low to High</div>
                                        <div onClick={() => handleClick('desc')} className={`${activeSortBy === 'High to Low' ? 'primaryColor' : ''}`}>Price: High to Low</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                )}
            </Row>
            {productcatFiltersData.hasOwnProperty("display_items") ? (
                <>
                    <Row className=' listing-products'>
                        {productcatFiltersData.display_items.products && productcatFiltersData.display_items.products.length > 0 ? (
                            productcatFiltersData.display_items.products.map((ele, index) => {
                                const detailUrl = ele.variantList.length > 0 ? `${ele.slug}?variant=${ele.variantList[0].variantid}` : ele.slug;
                                return (

                                    <Col xxl={3} xl={3} lg={3} md={4} sm={6} xs={6} key={index} className="product-list">
                                        {type === 'bundel' ? (
                                            <Cardproduct image={ele.product_images[0]} title={ele.product_title} rating={ele.rating} slug={ele.slug} status={ele.type} price={ele.selling_price} discount={ele.discount_percentage} productid={ele.productid} mrp={ele.mrp} variantList={ele.variantList} stock_status={ele.stock_status} product_type={ele.product_type} />
                                        ) : (
                                            <Cardproduct image={ele.product_images[0]} title={ele.product_title} rating={ele.rating} slug={ele.slug} status={ele.type} price={ele.selling_price} discount={ele.discount_percentage} productid={ele.productid} mrp={ele.mrp} variantList={ele.variantList} stock_status={ele.stock_status} product_type={ele.product_type} />
                                        )}
                                    </Col>

                                );
                            })
                        ) : (
                            <div className='custom-height'></div>
                        )}
                    </Row>
                </>
            ) : (
                productcatFiltersData.hasOwnProperty("result") ? <div>No products found.</div> : <div className='custom-height'></div>
            )}
            {
                filtertotaldata.filters > categorydata &&

                <Row className='react-pagination-maindiv'>
                    <Col lg={6} md={6} sm={12} xs={12} className='react-paginate-left'>Page {filterPage} of <span>{Math.ceil(filtertotaldata.filters / categorydata)}</span> </Col>
                    <Col lg={6} md={6} sm={12} xs={12} className='react-paginate-right'>
                        {
                            categorydata > 0 &&
                            <ReactPaginate
                                previousLabel={<><MdKeyboardArrowLeft /> {"Previous"}</>}
                                nextLabel={<>{"Next"} <MdOutlineKeyboardArrowRight /></>}
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                pageCount={Math.ceil(filtertotaldata.filters / categorydata)}
                                pageRangeDisplayed={4}
                                marginPagesDisplayed={1}
                                containerClassName="pagination justify-content-center"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                forcePage={filterPage - 1} // Set active page
                                activeClassName="active"
                                onPageChange={handlePageClick}
                                renderOnZeroPageCount={null}
                            />
                        }
                    </Col>
                </Row>
            }
        </Col>
    );
});

export default CatProducts;
