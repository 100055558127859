import React, { useContext, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Row, Col } from 'reactstrap';
import {  BillingAddress, PaymentMethodComp, SuccessOrder } from '../components/common';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import OrderAction from '../redux/actions/OrderAction';
import CartAction from '../redux/actions/CartAction';
import { useSelector,useDispatch } from 'react-redux';
const OrderSuccess = () => {
    const dispatch = useDispatch()
  const userData = useSelector(state => state.masterdata.userData);
  const billingformValues = useSelector(state => state.addressdata.billingformValues);
  const orderDetail = useSelector(state => state.orderdetail.orderDetail);
    const { slug } = useParams();
    const { updateCart } = CartAction()
    const { getOrderDetailById } = OrderAction()
    useEffect(() => {
        var updated_Data = {
            "order_id": slug,
        }
        dispatch(getOrderDetailById(slug))
        dispatch(updateCart(updated_Data))

    }, [slug])
    useEffect(() => {
        dispatch({
          type: 'setHeadercategory',
          payload: false,
        });
      }, []);



    return (
        <div>
            <Container>
                <Row className='mt-3 mb-5'>
                    {
                        orderDetail.length > 0 ?
                        <>
                            <Col lg={8} md={12}>
                                <SuccessOrder orderDetail={orderDetail[0].order_details.map(ele=>ele)} orderno={orderDetail[0]} />
                            </Col>
                            <Col lg={4} md={12}>
                                <div className='successOrdersDetails'>
                                    {
                                        orderDetail[0]?.shippingaddress &&
                                    
                                    <div className='billingAddress'><BillingAddress heading={"Shipping Address"} name={orderDetail[0]?.shippingaddress?.name} phone={orderDetail[0].shippingaddress.phone} address={orderDetail[0].shippingaddress.address} state={orderDetail[0].shippingaddress.state} /></div>
}
                                    {/* <div dangerouslySetInnerHTML={{ __html: orderDetail[0].shippingaddress }} /> */}

                                    {
                                        (userData.hasOwnProperty("cust_type") && userData.cust_type == "2") &&
                                        <div className='shippingAddress'><BillingAddress heading={"Billing Address"} name={billingformValues.billingname} phone={billingformValues.billingphone} address={billingformValues.billingaddress} state={billingformValues.billingstate} /></div>
                                    }


                                    {/* <div className='successPaymentMethods'>
                                        <div className='payment_method mt-3'>
                                            <PaymentMethodComp screentype={"order-detail"} paymentData={orderDetail[0]} />
                                        </div>
                                    </div> */}
                                </div>
                                <Row>
                                    <div className='buttons-product-detaildiv'>
                                        <NavLink to="/" className="text-decoration-none">
                                            <div className='product-detail-buttongroup' >
                                                <div className='product-detail-button primaryColor ordersuccess-btn text-decoration-none'>
                                                    Continue Shopping
                                                </div>
                                            </div>
                                        </NavLink>

                                    </div>
                                </Row>
                            </Col>
                        </>
                       :<div className='custom-height'></div>
                    }

                </Row>
            </Container>
        </div>
    );
};

export default OrderSuccess;