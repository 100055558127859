import React, { useState } from 'react';
import { BsDash, BsPlus } from "react-icons/bs";
import { useCollapse } from 'react-collapsed';
import { useSearchParams, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IoCheckmarkOutline } from "react-icons/io5";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
const CheckBox = ({ item }) => {
  const dispatch = useDispatch();
  const checkItems = useSelector(state => state.catlogdetail.checkItems);
  const [searchParams, setSearchParams] = useSearchParams();
  const { slug } = useParams();
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded: true,
  });
  const [searched, setSearched] = useState('');

  const handler = e => {
    setSearched(e.target.value);
  };

  const handelCheckBox = (ele) => {
    const value = ele.attr_value_id;
    const isChecked = checkItems.includes(value);

    if (!isChecked) {
      dispatch({ type: 'setCheckItems', payload: [...checkItems, value] });
      if (searchParams.has(item.title)) {
        const ParamsValue = searchParams.get(item.title);
        searchParams.delete(item.title);
        searchParams.set(item.title, ParamsValue + "," + value);
      } else {
        searchParams.set(item.title, value);
      }
    } else {
      const ParamsValue = searchParams.get(item.title);
      const paramsArray = ParamsValue.split(",");
      const filteredArray = paramsArray.filter(ele => ele !== value);
      searchParams.set(item.title, filteredArray.toString());
      if (filteredArray.length <= 0) {
        searchParams.delete(item.title);
      }
      dispatch({ type: 'setCheckItems', payload: checkItems.filter(each => each !== value) });
    }
    setSearchParams(searchParams);
  };

  return (
    <div className=''>
      <div {...getToggleProps()}>
        <button type="button" className="menuShow filterTitleContainer">
          <p className="filterTitle">{item.title}</p>
          <div>{isExpanded ? <FaAngleUp /> : <FaAngleDown />}</div>
        </button>
      </div>

      <div className="mb-2 filterItem" {...getCollapseProps()}>
        <div className='filterItems'>
          {item.title !== "Color" && (
            <div className='search' style={{ borderBottom: "unset" }}>
              {
                item.list.length > 3 &&
                <input
                  type='search'
                  onChange={handler}
                  value={searched}
                  className="search"
                  placeholder={`Search for ${item.title}`}
                />
              }

            </div>
          )}
          <div className={`  ${item.list.length >= 5 ? "textLength " : ""}`}>
            <ul className={item.title == "Color" ? 'parent' : ""}>
              {item.list
                .filter(ele => ele.hasOwnProperty('attr_value_name') && ele.attr_value_name.toLowerCase().includes(searched.toLowerCase()))
                .sort((a, b) => a.attr_value_name.localeCompare(b.attr_value_name))
                .map((ele, index) => (
                  <li key={index}>
                    <div className='list'>
                      {item.title === "Color" ? (
                        <div
                          className="color-swatch"
                          style={{
                            backgroundColor: ele.attr_value_name.toLowerCase(),
                            width: "30px",
                            height: "30px",
                            display: "inline-block",
                            marginRight: "8px",
                            borderRadius: "2px",
                            border: checkItems.includes(ele.attr_value_id) ? "3px solid #ccc" : "1px solid #ccc",
                            cursor: "pointer",
                            // opacity: checkItems.includes(ele.attr_value_id) ? 0.5 : 1
                          }}
                          onClick={() => handelCheckBox(ele)}
                          title={ele.attr_value_name}
                        ></div>
                      )
                        : (
                          <>
                            <input
                              type='checkbox'
                              id={`inline-checkbox-${ele.attr_value_id}`}
                              value={ele.attr_value_id}
                              checked={checkItems.includes(ele.attr_value_id)}
                              className="checkbox-custom d-none"
                              name="checkeditem"
                              onChange={() => handelCheckBox(ele)}
                            />
                            <div className='d-flex justify-content-between'>
                              <label className='filtercontent' htmlFor={`inline-checkbox-${ele.attr_value_id}`}>{ele.attr_value_name}</label>
                              <div>
                                {
                                  checkItems.includes(ele.attr_value_id) ?
                                    <IoCheckmarkOutline className='filterrighticon' /> : ""
                                }

                              </div>
                            </div>
                          </>
                        )}
                    </div>
                  </li>
                ))}
            </ul>

          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckBox;
